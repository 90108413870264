// FirebaseProvider.tsx

import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Your config that you stored in the env file.
const prodConfig = {
  apiKey: "AIzaSyB3vQIGl7psddAhZwLa-wkOQRomq8dgzC4",
  authDomain: "afterword.com",
  databaseURL: "https://afterword-prod.firebaseio.com",
  projectId: "afterword-prod",
  storageBucket: "afterword-prod.appspot.com",
  messagingSenderId: "331061227156",
  appId: "1:331061227156:web:86b74f87c623b8e3b48e81",
  measurementId: "G-57PDW9DVPQ",
};
const sandboxConfig = {
  apiKey: "AIzaSyBigan0Y4Rjj0eGN2qyxvOaAY-D9fYFikU",
  authDomain: "sandbox.afterword.com",
  databaseURL: "https://afterword-sandbox.firebaseio.com",
  projectId: "afterword-sandbox",
  storageBucket: "afterword-sandbox.appspot.com",
  messagingSenderId: "298623872728",
  appId: "1:298623872728:web:87d3a3e66863dda24eb1ad",
  measurementId: "G-84VMQ0M1PX",
};
const firebaseConfig =
  process.env.NODE_ENV === "development" ||
  (typeof window === "object" && location.hostname.startsWith("sandbox"))
    ? sandboxConfig
    : prodConfig;

// The type definition for the firebase context data.

export interface FirebaseContextData {
  isInitialized: boolean;
  firebase: typeof firebase;
  db: typeof firebase.firestore;
  authToken: string | null;
  setAuthToken: (authToken: string) => void;
  userEmail: string | null;
  isAdmin: boolean;
}
// The firebase context that will store the firebase instance and other useful variables.

export const FirebaseContext = React.createContext<FirebaseContextData>({
  authToken: null,
  firebase,
  db: null,
  isInitialized: false,
  setAuthToken: () => {},
  userEmail: null,
  isAdmin: false,
});

// The provider that will store the logic for manipulating the firebase instance and variables.

export const FirebaseProvider: React.FC = ({ children }) => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState(null);
  const [db, setDb] = React.useState(null);

  // If we have a window and the authToken already exists in localstorage then initialize the authToken value otherwise null.

  const [authToken, setAuthToken] = React.useState<
    FirebaseContextData["authToken"]
  >(
    typeof window === "object" ? window.localStorage.getItem("authToken") : null
  );

  // If firebase has not been initialized then initialize it.
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    let db = firebase.firestore();
    setDb(db);
    setIsInitialized(true);
  }

  // A method for setting the authToken in state and local storage.
  const onSetAuthToken = (token: string) => {
    setAuthToken(token);
    localStorage.setItem("authToken", token);
  };

  if (typeof window === "object" && firebase) {
    // TODO: clean this up
    firebase.auth().onAuthStateChanged(function (user) {
      let userEmail = (user && user.email) || "";
      setUserEmail(userEmail);
      let isAdmin =
        userEmail.endsWith("@afterword.com") ||
        userEmail.endsWith("@afterword.co");
      setIsAdmin(isAdmin);

      // console.log(`Logged in as ${userEmail} (admin=${isAdmin})`);
    });
  }

  // If we have the window object and there is no authToken then try to get the authToken from local storage.
  React.useEffect(() => {
    if (typeof window === "object" && !authToken) {
      const token = window.localStorage.getItem("authToken");

      if (token) {
        onSetAuthToken(token);
      }
    }
  }, [authToken]);

  return (
    <FirebaseContext.Provider
      value={{
        authToken,
        firebase,
        db,
        isInitialized,
        setAuthToken: onSetAuthToken,
        userEmail,
        isAdmin,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
