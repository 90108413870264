export default {
  colors: {
    text: "#333",
    background: "#fff",
    // primary: "#2C2932",
    black: "#000000",
    primary: "#1A395B",
    secondary: "#BFCBBA",
    white: "#FFF",

    buttonColor: "#657075",
    buttonOrange: "#B99891",
    // buttonBlue: "#405B72",
    buttonBlue: "#487A98",
    buttonBlueHover: "#405B72",
    // buttonBlueHover: "#86A7BB",

    // button blue: #657075
    // Gumbo
    // Fix this green: #5D6F6B

    green: "#BFCBBA",
    // darkBlue: "#065063",
    darkBlue: "#1A395B",
    navy: "#1A395B",
    // beige: "#f3f2ef", // linkedin
    beige: "#F4F3EE",
    // beige: "#FCF9F5", // the wing

    pink: "#F1DDD2",
    orange: "#C4897B",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  buttons: {
    primary: {
      color: "white",
      bg: "buttonColor",
      lineHeight: "150%",
      padding: "12px 24px",
      fontSize: ["12px", "auto"],
      fontWeight: "600",
      // height: "32px",
      "&:hover": {
        cursor: "pointer",
        bg: "buttonOrange",
      },
      "&:focus": {
        outlineColor: "buttonColor",
      },
    },
    blueButton: {
      color: "white",
      bg: "buttonBlue",
      lineHeight: "150%",
      padding: "12px 24px",
      fontSize: ["12px", "auto"],
      fontWeight: "600",
      // height: "32px",
      "&:hover": {
        cursor: "pointer",
        bg: "buttonBlueHover",
      },
      "&:focus": {
        outlineColor: "buttonBlue",
      },
    },
    addToCart: {
      color: "white",
      bg: "buttonColor",
      lineHeight: "150%",
      padding: "6px 12px",
      fontSize: ["12px", "auto"],
      fontWeight: "600",
      // height: "32px",
      "&:hover": {
        cursor: "pointer",
        bg: "buttonOrange",
      },
      "&:focus": {
        outlineColor: "buttonColor",
      },
    },
    secondary: {
      color: "navy",
      bg: "white",
      border: "1px solid transparent",
      borderColor: "black",
      padding: "12px 24px",
      fontSize: ["12px", "auto"],
      fontWeight: "600",
      // height: "32px",
      "&:hover": {
        bg: "buttonColor",
        color: "white",
        // borderColor: "white",
        cursor: "pointer",
      },
      "&:focus": {
        outlineColor: "white",
      },
    },
    inverted: {
      color: "black",
      bg: "white",
      border: "1px solid transparent",
      borderColor: "black",
      padding: "12px 24px",
      fontSize: ["12px", "auto"],
      fontWeight: "600",
      // height: "32px",
      "&:hover": {
        bg: "buttonColor",
        color: "white",
        cursor: "pointer",
      },
      "&:focus": {
        outlineColor: "white",
      },
    },
  },
  outline: {
    color: "navy",
    bg: "transparent",
    border: "1px solid black",
    lineHeight: "150%",
    padding: "12px 24px",
    fontSize: ["12px", "auto"],
    fontWeight: "600",
    // height: "32px",
    "&:hover": {
      cursor: "pointer",
      bg: "buttonOrange",
      borderColor: "transparent",
      color: "white",
    },
    "&:focus": {
      outlineColor: "buttonColor",
    },
  },
  links: {
    nav: {
      color: "black",
      textDecoration: "none",
      marginRight: ["0", "0.8em", "1.2em"],
      height: "60px",
      fontWeight: "500",
      "&:hover": {
        color: "primary",
      },
      "&:focus": {
        outline: "0",
        outlineColor: "buttonColor",
      },
    },
    logo: {
      color: "black",
      textDecoration: "none",
      fontFamily: "Georgia, serif",
      fontStyle: "normal",
      fontWeight: "body",
      fontSize: 5,
    },
    footer: {
      color: "black",
      marginBottom: 2,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  fonts: {
    body: "Avenir",
    hero: "Inter",
    memorials: "Montserrat, system-ui, -apple-system",
    heading: "Georgia, serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },

  breakpoints: ["768px", "1025px", "1200px", "1500px"],
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    h1: {
      variant: "text.heading",
      fontSize: [4, 5, "54px"],
      // TODO add 54 between 6 & 7
      lineHeight: ["40px", "60px", "80px"],
    },
    h2: {
      variant: "text.heading",
      fontSize: [3, 4, "36px"],
      fontWeight: "body",
      lineHeight: ["30px", "45px", "60px"],
    },
    h3: {
      fontSize: ["12px", "18px", "24px"],
      fontWeight: "500",
    },
    text: {
      feature: {
        fontSize: ["12px", "18px", "20px"],
        fontWeight: "700",
      },
    },
    h4: {
      fontSize: ["16px", "18px", "30px"],
      fontWeight: 400,
    },
    hr: {
      height: "1px",
      border: 0,
      backgroundColor: "primary",
      marginBottom: 4,
    },
  },
};
