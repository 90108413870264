/* autoprefixer grid: autoplace */

// custom typefaces
import "typeface-montserrat";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";
// shopify
import "./src/shopify.css";

import React from "react";
import { FirebaseProvider } from "./src/services";

// Polyfills for IE, Firefox, etc.
import fetch from "isomorphic-fetch";
import "url-search-params-polyfill";
import "formdata-polyfill";
import { polyfill } from "es6-promise";
polyfill();

// highlight-start
export const wrapRootElement = ({ element }) => (
  <FirebaseProvider>{element}</FirebaseProvider>
);
// highlight-end
